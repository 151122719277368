import type { SiteSiteMetadata } from '@generated/graphql'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useSession } from 'src/sdk/session'
import StockAvailable from 'src/components/common/StockAvailable/StockAvailable'
import Newsletter from 'src/components/sections/Newsletter'

import '../../styles/pages/terminos-y-condiciones.scss'

interface Props {
  data: TAllCmsInstitutional
  location: {
    pathname: string
  }
}

interface TAllCmsInstitutional {
  allCmsInstitutionalPage: TEdges
  site: {
    siteMetadata: SiteSiteMetadata
  }
}

type TEdges = {
  edges: Array<{ node: { sections: Array<{ data: { iframe: string } }> } }>
}

const Page = ({ location, data: { site } }: Props) => {
  const { locale } = useSession()
  const title = site?.siteMetadata?.title ?? ''

  return (
    <>
      <GatsbySeo
        title={title}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        description={site?.siteMetadata?.description ?? ''}
        canonical={location.pathname}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <div style={{ paddingTop: '16px' }} />
      <section className="terminos-content">
        <div className="content-header">
          <h2 className="page-title">Términos y Condiciones Generales.</h2>
          <div className="black-border"></div>
        </div>
        <div className="section-title">
          <span className="section-title-text">
            Términos y Condiciones Generales.
          </span>
          <span className="section-subtitle-text">
            TÉRMINOS Y CONDICIONES PARA LA VENTA EN TATA ONLINE
          </span>
        </div>
        <div className="download-container">
          <a href="https://tatauy.vteximg.com.br/arquivos/terminos-y-condiciones.pdf?v=637763840869430000">
            <img
              src="https://tatauyqa.vtexassets.com/arquivos/descargar icon.png"
              alt="descargar pagina"
            />
          </a>
        </div>
        <div className="section-text">
          <p>
            Supermercados TA-TA (en adelante TATA), razón social TA-TA Sociedad
            Anónima, constituida en la República Oriental del Uruguay, inscripta
            en el Registro Único Tributario con el número 210003270017,
            domiciliada en José de Béjar 2600, en Montevideo, con número
            telefónico 25089600, gestiona y es titular del sitio web{' '}
            <a href="www.tata.com.uy" className="tata-link">
              www.tata.com.uy
            </a>
          </p>
          <p>
            El presente documento describe los términos y condiciones generales
            aplicables a la utilización, navegación y compra de los productos
            ofrecidos por TATA, en y a través del sitio{' '}
            <a href="www.tata.com.uy" className="tata-link">
              www.tata.com.uy
            </a>
          </p>

          <p>
            Para la funcionalidad y uso del Sitio es esencial el Programa Plus.
            El Cliente declara conocer y aceptar lo antes dicho, así como en su
            totalidad las Bases y Condiciones del Programa Plus que se adjuntan
            como Anexo I y se encuentran disponibles en el link:{' '}
            <a
              href="            https://www.plus.uy/bases-y-condiciones.
"
              className="tata-link"
            >
              https://www.plus.uy/bases-y-condiciones.
            </a>{' '}
            Una vez que el Usuario se registra en{' '}
            <a href="www.tata.com.uy" className="tata-link">
              www.tata.com.uy
            </a>{' '}
            automáticamente queda ingresado al sistema de beneficios plus,
            siendo a partir de entonces "Cliente Plus".
          </p>
          <p>
            Plus es el programa de beneficios de Supermercados Ta-Ta, BAS y
            Multi Ahorro Hogar. Los Clientes podrán obtener a partir de la
            adquisición de productos, “Puntos Canjeables” por cualquier producto
            de las marcas mencionadas y accederán a los Precios Plus
            diferenciales en todos los productos que estén seleccionados en la
            promoción vigente. Los Puntos Canjeables se pueden utilizar en
            cualquier local físico de Ta-Ta, BAS y Multi Ahorro Hogar, pero no
            pueden utilizarse para canje en los canales e-commerce.
          </p>
          <p>
            Se entenderá por Usuario a los visitantes del sitio{' '}
            <a href="www.tata.com.uy" className="tata-link">
              www.tata.com.uy
            </a>
            , ya sea Usuario registrado o simple navegador (en adelante el
            “Usuario” o “Cliente”). El Usuario declara conocer y aceptar los
            términos y condiciones presentes al ingresar al sitio web. En
            consecuencia, todas las visitas y todos los contratos y
            transacciones que se realicen en este sitio, como asimismo sus
            efectos jurídicos, quedarán regidos por estas reglas y sometidas a
            la normativa de la República Oriental del Uruguay en la materia.
          </p>
          <p>
            Los términos y condiciones contenidos en este instrumento se
            aplicarán y se entenderán como formando parte de todos los actos y
            contratos que se ejecuten o celebren mediante los sistemas de oferta
            y comercialización comprendidos en este sitio web entre los Usuarios
            del mismo.
          </p>
          <p>
            De existir desacuerdo del Usuario en cuanto a los términos y
            condiciones, los que poseen carácter obligatorio y vinculante,
            deberá abstenerse de utilizar el sitio y/o los servicios.
          </p>
          <p>
            El Usuario que ingrese a este sitio web{' '}
            <a href="www.tata.com.uy" className="tata-link">
              www.tata.com.uy
            </a>
            , deberá leer, entender y aceptar los presentes términos y
            condiciones, así como a los demás documentos que se incorporen.
          </p>

          <p>
            Cada vez que el Usuario utilice este sitio significará aceptación
            plena y expresa de los términos y condiciones establecidos, así como
            de los demás documentos que se incorporen al sitio web por TATA,
            obligándose expresamente por el solo uso del mismo.
          </p>
          <p>
            TATA se reserva el derecho de realizar modificaciones a los
            presentes términos y condiciones de uso en el Sitio y en las
            notificaciones que correspondieren, con la simple modificación del
            presente, y en cualquier momento, comunicándolo a través de la
            publicación en el Sitio.
          </p>
        </div>
        <div className="numbered-items">
          <span className="number-item-title">1. CAPACIDAD</span>
          <div>
            <p>
              Las compras sólo podrán efectuarse por personas con capacidad
              legal para contratar. Los actos que incapaces realicen en el Sitio
              serán responsabilidad de sus padres, tutores o curadores, y por
              tanto se considerarán realizados por éstos en ejercicio de la
              representación legal con la que cuentan. En caso que se registre
              como Usuario una persona jurídica, quien la representa, deberá
              tener capacidad legal para contratar a nombre de la misma y
              obligarla en los términos de este acuerdo.
            </p>
          </div>
          <span className="number-item-title">2. REGISTRO</span>
          <div>
            <p>
              Es obligatorio completar el formulario de registro en todos sus
              campos con datos válidos para la adquisición de productos y
              servicios ofrecidos en este sitio. El Usuario deberá completarlo
              con su información personal de manera exacta, precisa y verdadera
              ("Identificación") y asume el compromiso de actualizar sus Datos
              Personales conforme resulte necesario. TATA podrá utilizar
              diversos medios para identificar a sus Usuarios, pero TATA NO se
              responsabiliza por la certeza de los Datos Personales provistos
              por sus Usuarios. Los Usuarios garantizan y responden, en
              cualquier caso, de la veracidad, exactitud, vigencia y
              autenticidad de los Datos Personales ingresados.
            </p>
            <p>
              El Usuario registrado o visitante del sitio será responsable de
              sus Datos, usuario y contraseña, y de los eventuales perjuicios
              que padece por sí, o que causa a terceros o a TATA, a consecuencia
              de informar o tratar erróneamente sus datos personales.
            </p>
            <p>
              TATA se reserva el derecho de solicitar cualquier comprobante y/o
              dato adicional a efectos de corroborar los Datos Personales, así
              como de suspender temporal o definitivamente a aquellos Usuarios
              cuyos datos no hayan podido ser confirmados. En estos casos de
              inhabilitación, se dará de baja la compra efectuada, sin que ello
              genere algún derecho a resarcimiento o responsabilidad por parte
              de TATA S.A.
            </p>
            <p>
              El Usuario será responsable por todas las operaciones efectuadas
              en su Cuenta, sea el uso por sí y/o por terceros, pues el acceso a
              la misma está restringido al ingreso y uso de su correo
              electrónico, salvo por aquellas que sean realizadas por culpa o
              negligencia exclusiva de la Empresa. El Usuario se compromete a
              notificar a TATA en forma inmediata y por cualquiera de los
              medios, cualquier uso no autorizado de su Cuenta, así como el
              ingreso por terceros no autorizados a la misma. Se aclara que está
              prohibida la venta, cesión o transferencia de la Cuenta bajo
              ningún título.
            </p>
          </div>

          <span className="number-item-title">
            3. PRIVACIDAD Y DATOS PERSONALES
          </span>
          <div>
            <p>
              Los datos personales proporcionados serán tratados por TA TA S.A.
              según lo establecido en la Ley Nº 18.331 del 11 de agosto de 2008
              y su decreto reglamentario Nº 414/2009 del 31 de agosto de 2009,
              modificativas y concordantes. Por mayor información, ver Política
              de Privacidad de la Web.
            </p>
            <p>
              TA TA S.A. podrá utilizar cookies cuando se utilice la página web.
              No obstante, el usuario podrá configurar su navegador para ser
              avisado de la recepción de las cookies e impedir en caso de
              considerarlo adecuado, su instalación.
            </p>
          </div>

          <span className="number-item-title">
            4. USO Y ACEPTACIÓN DE CONTRATOS A TRAVÉS DEL SITIO. GENERALIDADES
          </span>
          <div>
            <p>
              En los contratos ofrecidos por medio de este sitio, TATA
              informará, de manera inequívoca y fácilmente accesible, los pasos
              que deben seguirse para celebrarlos, e informará, cuando
              corresponda, si el documento electrónico en que se formalice el
              contrato será archivado y si éste será accesible al consumidor.
            </p>
            <p>
              El sólo hecho de seguir los pasos para realizar una compra,
              implica aceptación expresa de los presentes Términos y Condiciones
              por parte del Usuario, y que la empresa oferente efectivamente ha
              dado cumplimiento a las condiciones contenidas en este numeral.
            </p>
            <p>
              Las fotografías o ilustraciones publicadas en el Sitio lo son solo
              a efectos ilustrativos, y la información de los productos podrá
              variar en caso de errores involuntarios, sin que ello implique
              responsabilidad alguna de TATA.
            </p>
            <p>
              Los Clientes aceptan que los precios de los productos en la página
              Web de TATA puedan variar respecto de los colocados de los locales
              físicos de TATA, y que, en base a ello, no podrán basar ningún
              reclamo por diferencia de precios. Asimismo, TATA podrá aplicar
              promociones sobre determinados productos exclusivamente para venta
              web, o exclusivamente para venta en los locales, sin obligación de
              tener que extender dichas promociones a otras vías de venta no
              comunicadas en la información de la promoción. Asimismo, TATA
              puede establecer mínimos o máximos en la cantidad, volumen o
              precio de los productos, según sea el caso.
            </p>
          </div>
          <span className="number-item-title">
            5. FORMACIÓN DEL CONSENTIMIENTO LOS CONTRATOS CELEBRADOS EN ESTE
            SITIO Y VALIDACIÓN DE LOS MISMOS
          </span>
          <div>
            <p>
              A través de este sitio web TATA realizará ofertas de bienes, que
              podrán ser aceptadas, por vía electrónica o telefónica, y
              utilizando los mecanismos que el mismo sitio ofrece para ello.
              Toda aceptación de oferta quedará sujeta a la condición suspensiva
              de que la empresa oferente (TATA) valide la transacción. En
              consecuencia, para toda operación que se efectúe en este sitio, la
              confirmación y/o validación o verificación por parte de TATA, será
              requisito para la formación del consentimiento. Para validar la
              transacción TATA deberá verificar: a) Que dispone, en el momento
              de la aceptación de oferta, de las especies y cantidades en stock.
              b) Que valida y acepta el medio de pago ofrecido por el Usuario.
              c) Que los datos registrados por el cliente en el sitio coinciden
              con los proporcionados al efectuar su aceptación de oferta. d) Que
              la publicación de la oferta no adolece de ningún error (incluyendo
              sin limitar, error manifiesto en el precio publicado, o en las
              características esenciales del producto).
            </p>
            <p>
              La recepción de correos electrónicos confirmando la recepción del
              pedido y/o informando el comienzo de preparación del pedido, no
              significarán validación de la transacción por parte de TATA. Son
              correos de envío automático, por lo que cualquier transacción
              podrá ser no aceptada o no validada por TATA aún con posterioridad
              al envío de los correos antedichos, una vez constatados todos los
              extremos estipulados. La validación se configurará cuando el
              Usuario reciba un correo electrónico que indique que el Pedido ha
              sido FACTURADO.
            </p>
            <p>
              El Usuario acepta que TATA, luego de recibir un pedido, por
              distintas razones puede no estar en condiciones de aceptarlo total
              o parcialmente y deba por ello cancelar o excluir del mismo
              algunos productos pedidos por el Usuario. Esto puede ocurrir
              cuando sucedan determinadas situaciones, incluyendo, sin limitar:
              debido a limitaciones en las cantidades disponibles para la
              compra; faltantes de mercadería, falta de autorización a la
              operación por parte de la entidad emisora o administradora de su
              tarjeta de crédito; inexactitudes o errores en el producto o
              información; o problemas identificados por el departamento de
              prevención de fraude de crédito y de precios.
            </p>
            <p>
              El Sitio sólo se puede utilizar con fines lícitos de oferta de
              productos a los posibles Clientes, y la adquisición por parte de
              ellos de los productos ofrecidos a través de este medio. Los
              Usuarios se obligan a mantener indemne a TATA de cualquier
              reclamación extrajudicial, judicial o administrativa que pueda
              plantear cualquier tercero, por el uso indebido del Sitio por
              parte del Cliente y de la actividad que éste desarrolle en el
              mismo. Asimismo, siendo el propósito del Sitio permitir a los
              Usuarios realizar compras online, sin concurrir personalmente a
              las sucursales, TATA se esforzará por proporcionar información
              precisa sobre esos precios, sin perjuicio de lo cual, pueden
              ocurrir errores. En el caso de que un artículo figure en las
              listas del Sitio a un precio incorrecto, TATA tendrá el derecho,
              de corregir la orden facturando el precio vigente al momento del
              armado del pedido o bien, de anular el pedido reintegrando al
              Usuario los importes abonados. En este caso, se dará aviso del
              error al Usuario, y con él, la posibilidad de cancelar o modificar
              la compra del producto facturado u ofrecido de forma incorrecta.
            </p>
            <p>
              A efectos de comunicar al Usuario o consumidor acerca de la
              validación del pedido, la empresa oferente deberá enviar una
              confirmación escrita a la misma dirección electrónica que haya
              registrado el Usuario aceptante de la oferta, o por cualquier
              medio de comunicación que garantice el debido y oportuno
              conocimiento del consumidor. El consentimiento se entenderá
              formado desde el momento en que se envía esta confirmación escrita
              al Usuario. No se considerará “validación” o confirmación
              definitiva, el correo de confirmación de recepción de pedido o de
              comienzo de preparación del mismo. La validación se configurará
              cuando el Usuario reciba un correo electrónico que indique que el
              Pedido ha sido FACTURADO.
            </p>
          </div>

          <span className="number-item-title">6. CONFIRMACIÓN DE PEDIDO</span>
          <div>
            <p>
              El Cliente confirmará el pedido realizado haciendo “click” en el
              botón FINALIZAR dentro del proceso de pago. A partir de esta
              confirmación el pedido es firme y definitivo respecto del Cliente,
              quedará registrado de forma automática y TATA procederá a su
              validación, y posterior entrega, si correspondiere (si se
              cumpliere con lo dispuesto en el punto 5.). El registro automático
              del pedido realizado tiene valor de prueba en cuanto a su
              naturaleza, contenido y fecha. La confirmación del pedido por
              parte del Cliente conlleva la aceptación de la oferta y la previa
              lectura y aceptación de los presentes Términos y Condiciones,
              condiciones particulares, en su caso, y aviso legal. Una vez
              finalizado el proceso de compra, el sistema confirmará al Cliente
              la recepción del pedido por medio del envió de e-mail a la
              dirección de correo electrónico consignada al registrarse (Si el
              Usuario no recibe tal e-mail, deberá contactarse con el Atención
              al Cliente de TATA a fin de constatar que no hubo errores de
              registro al ingresar la casilla de correo). Sin embargo, la
              recepción de este e-mail no implica la validación de la
              transacción por parte de TATA, la que estará sujeta al proceso
              indicado en la punto 5 del presente.
            </p>
          </div>
          <span className="number-item-title">7. MEDIOS DE PAGO</span>
          <div>
            <p>
              TATA informará, en todos los casos, el precio de sus productos
              incluido los impuestos, la moneda, el tipo de cambio si
              correspondiere, así como los medios de pago y financiación. Los
              pagos podrán efectuarse con tarjetas de crédito Master Card,
              Líder, Diners (a través de Mercado Pago), crédito y débito Visa (a
              través de Siempre Pago), y Oca, Visa, Master, Cabal, Pass card,
              Creditel, Líder, Diners, Créditos Directos, Amex y Anda (a través
              de POS con el medio de pago Cobro contra entrega). Dichas
              entidades financieras regularán las condiciones y términos de
              pago. TATA no administra los datos transaccionales de cada
              Cliente, siendo las mismas administradas por los servidores de
              cada entidad crediticia.
            </p>
            <p>
              Se deja constancia que TATA no se hace responsable por los
              reintegros que deban efectuar los bancos o las instituciones
              financieras emisores de tarjetas de crédito, respecto de las
              promociones vigentes, siendo dichas empresas, las únicas
              responsables de efectuar en tiempo y forma el reintegro
              correspondiente.
            </p>
          </div>
          <span className="number-item-title">8. ENTREGA DE PEDIDO</span>
          <div>
            <p>
              El pedido será entregado al Cliente o a la persona autorizada por
              éste en oportunidad de efectuar la transacción, en la dirección
              indicada, siempre que se encuentre en zonas habilitadas por TATA
              para realizar entregas a domicilio. El Cliente o autorizado, en
              oportunidad de la recepción del o los artículos adquiridos,
              firmará el recibo de entrega correspondiente, consignando su
              nombre y documento de identidad, así como declarará haber
              examinado los mismos, por lo que implica su conformidad con los
              productos recibidos, tanto en cuanto a cantidad como a calidad y
              estado en que se encuentran, todo de conformidad a la “información
              sobre servicios de entrega de{' '}
              <a href="www.tata.com.uy" className="tata-link">
                www.tata.com.uy
              </a>
              ”, que forma parte de los presentes Términos y Condiciones.
            </p>
            <p>
              Si el Cliente no pudiera estar en su domicilio el día y turno
              horario elegidos para efectuar la recepción, deberá ponerse en
              contacto con el Servicio de Atención al Cliente para acordar otro
              día y/o turno de entrega.
            </p>
            <p>
              TATA informará, previo a efectuar la compraventa, el plazo para la
              entrega, el que se contará a partir de la confirmación de la
              transacción, sin perjuicio de demoras que pudieran originarse por
              causas ajenas a la voluntad de TATA, procediendo en todos los
              casos a informar al Cliente de esa situación, no siendo pasible de
              responsabilidad de tipo alguno por tal motivo.
            </p>

            <p>
              TATA se reserva el derecho de no enviar a domicilio los productos
              que a su juicio requieren mantenerse en ambiente refrigerado,
              como: Congelados, Jugos naturales, Frutas, Verduras, Rotisería,
              Carnicería y Pescadería así como cualquier otro a criterio de
              TATA.
            </p>
            <p>
              Ver más información en{' '}
              <a href="www.tata.com.uy" className="tata-link">
                www.tata.com.uy
              </a>
              : <span className="bolder-text-inst">Cómo comprar</span>
            </p>
          </div>

          <span className="number-item-title">
            9. CAMBIOS, DEVOLUCIONES Y DESISTIMIENTOS
          </span>
          <div>
            <p>
              El desistimiento o rescisión del contrato podrá efectivizarse
              siempre que el mismo sea comunicado a TATA, por cualquier medio
              fehaciente, dentro del plazo de siete (7) días hábiles de
              perfeccionado el contrato de compraventa.
            </p>
            <p>
              El proceso a estos efectos se encuentra previsto por las normas en
              materia de “Cambios y devoluciones de artículos adquiridos en la
              tienda online de TATA con o sin desperfectos”, que forma parte de
              los presentes Términos y Condiciones.
            </p>
            <p>
              Ver más información en{' '}
              <a href="www.tata.com.uy" className="tata-link">
                www.tata.com.uy
              </a>
              : <span className="bolder-text-inst">Cambios y devoluciones</span>
            </p>
          </div>
          <span className="number-item-title">10. COSTOS ADICIONALES</span>
          <div>
            <p>
              El comprador deberá abonar los costos de envío del/los artículos
              adquiridos. El precio del envío se facturará conjuntamente con la
              compraventa y de conformidad a la “Información sobre servicios de
              entrega” que forma parte de los presentes Términos y Condiciones.
            </p>
          </div>
          <span className="number-item-title">
            11. OPERATIVIDAD Y FALLAS EN EL SITIO WEB
          </span>
          <div>
            <p>
              TATA no se responsabiliza sobre desperfectos que pudieren
              sobrevenir en el sitio web que impidan su utilización, los que
              podrán ser por diversas causas, del sistema, técnicas, derivadas
              del servicio de internet, pudiéndose suspender el sitio en ocasión
              de su remodelación. En caso que ello ocurra será puesto en
              conocimiento de los Usuarios en la misma página web, por un
              periodo de tiempo prudencial, antes de procederse a la suspensión
              del mismo. El servicio ofrecido a través de este Sitio podrá
              sufrir suspensiones por razones técnicas o por trabajos de
              mantenimiento, o verse interrumpido o suspendido por causas no
              imputables a TATA como ser fuerza mayor o caso fortuito, sin que
              por ello se incurra en responsabilidad alguna, y sin derecho del
              Cliente de reclamar indemnización o compensación de clase alguna
              por la interrupción o suspensión.
            </p>
            <p>
              El Usuario se compromete a no acceder ni a intentar acceder al
              Sitio, ni usar de ninguno de los Servicios por ningún otro medio
              que no sea la interfaz provista por TATA. Asimismo, se compromete
              a no involucrarse en ninguna actividad que interfiera o que
              interrumpa o que tuviere entidad suficiente para interferir o
              interrumpir la prestación de los servicios del Sitio o los
              servidores y redes conectados al mismo. Está prohibido a los
              Usuarios violar, vulnerar y/o de cualquier forma afectar el normal
              uso y la seguridad del Sitio incluyendo, pero sin limitarse a: a)
              acceder a datos que no se destinan a ese Usuario o ingresar a una
              computadora o a una cuenta a los cuales no esté el Usuario
              autorizado para acceder; b) tratar de sondear, analizar o probar
              la vulnerabilidad de un sistema o una red o romper medidas de
              seguridad o autenticación sin la debida autorización; c) intentar
              interferir con el servicio a cualquier Usuario, computadora o red.
              Las violaciones de la seguridad del sistema o de la red pueden dar
              lugar a responsabilidad civil o penal. El Usuario y/o el navegante
              ocasional del Sitio o de la App aceptan y reconocen expresamente
              que cualquier hecho, acto u omisión vinculadas con lo referido
              anteriormente y que tengan entidad para afectar la seguridad y/o
              el normal desarrollo del Sitio tienen entidad suficiente para
              causar a TATA daños y perjuicios incluyendo sin limitar: lucro
              cesante por merma en las ventas, afectación de sus marcas, imagen
              comercial, pérdida de clientela, entre otros.
            </p>
          </div>
          <span className="number-item-title">
            12. DERECHOS DE PROPIEDAD INTELECTUAL Y DE PROPIEDAD INDUSTRIAL
          </span>
          <div>
            <p>
              El diseño de la presente Página web, así como todas las imágenes,
              productos, códigos fuente, logos, marcas y demás, en ella
              publicadas, son de la titularidad de TA-TA S.A. por lo que su uso
              indebido, reproducción, distribución, comunicación pública o con
              fines comerciales, transformación o cualquier otra actividad sin
              autorización expresa de su titular queda totalmente prohibida y
              será pasible de responsabilidad.
            </p>
          </div>
          <span className="number-item-title">13. ÁMBITO DE APLICACIÓN</span>
          <div>
            <p>
              Las condiciones y términos que se enumeran en el presente contrato
              son únicamente aplicables a las compras y a la navegación que se
              verifiquen exclusivamente a través de este sitio, quedando fuera
              de las mismas las compras realizadas en TATA a través de otros
              canales de venta, y siempre, dentro del territorio Nacional.
            </p>
          </div>
          <span className="number-item-title">
            14. NORMATIVA APLICABLE - JUZGADOS COMPETENTES
          </span>
          <div>
            <p>
              La normativa aplicable será la de la República Oriental del
              Uruguay, tanto en materia de derecho sustancial como procesal y
              los Tribunales Competentes serán los de la ciudad de Montevideo,
              República Oriental del Uruguay.
            </p>
          </div>
        </div>
        <div className="otros-container">
          <div className="section-title">
            <span className="section-title-text">OTROS</span>
          </div>
          <div>
            <p>
              Al usar el presente Sitio, deberá tenerse en consideración que, en
              ocasiones, los fabricantes pueden alterar sus empaques y
              etiquetas, de manera que el verdadero embalaje del producto y
              materiales adjuntos pueden contener información diferente de la
              que se exhibe. TATA sugiere al Usuario leer las etiquetas,
              advertencias e instrucciones antes de adquirir, utilizar o en
              cualquier forma consumir un producto. Para obtener información
              adicional acerca de un producto, deberá consultar con el
              fabricante, distribuidor o importador.
            </p>
            <p>
              El Usuario puede rescindir este contrato en cualquier momento,
              siempre que lo haga para el futuro evitando el uso de este Sitio y
              renunciando a su registro siguiendo el procedimiento especialmente
              previsto para ello. TATA podrá rescindir este contrato en
              cualquier momento siendo válida la notificación de tal rescisión a
              los domicilios reales o electrónicos que el Usuario tuviere
              registrados en el Sitio. Asimismo, TATA podrá cancelar sin previo
              aviso la condición de Usuario, y en consecuencia denegar el acceso
              a los Servicios del Sitio o a comprar por esta vía, si el Usuario
              no cumpliera con cualquier término o disposición de los presentes
              Términos y Condiciones, y/o, configure alguno de los siguiente
              extremos: hace uso indebido de un beneficio que corresponde a otro
              usuario, hace uso indebido del sitio, entre otros.
            </p>
            <p>
              TATA podrá en cualquier momento, temporal o permanentemente dar de
              baja este Sitio.
            </p>
          </div>
        </div>
        <div className="anexo-container">
          <div className="section-title">
            <span className="section-title-text">ANEXO I</span>
            <span className="section-subtitle-text">
              BASES Y CONDICIONES PROGRAMA DE BENEFICIOS PLUS DE TATA, MULTI
              AHORRO HOGAR Y BAS
            </span>
            <span className="section-subtitle-text">PROGRAMA DE FIDELIDAD</span>
          </div>

          <span className="number-item-title">1. OBJETO DEL PROGRAMA</span>
          <div>
            <p>
              1.1. Con el propósito de beneficiar y premiar a nuestros clientes
              TA-TA, MULTI AHORRO HOGAR, y BAS (en adelante la “Empresa”), TA-TA
              S.A. ha creado un Programa de puntos “PLUS”.
            </p>
            <p>
              1.2. Mediante la participación en el programa PLUS, en adelante el
              “Programa” los clientes podrán obtener a partir de la adquisición
              de productos de la Empresa, “Puntos Canjeables” por cualquier
              producto de la Empresa, descuentos, servicios, promociones y
              beneficios puntuales en algunos productos seleccionados por la
              Empresa, cuando corresponda.
            </p>
            <p>
              1.3. Los Puntos Canjeables obtenidos son para uso personal y no
              son transferibles.
            </p>
          </div>
          <span className="number-item-title">2. USUARIOS</span>
          <div>
            <p>
              2.1. Puede formar parte del programa “PLUS” toda persona física,
              mayor de 18 años, capaz, que haya aceptado las presentes bases y
              condiciones, en adelante “Bases y Condiciones”, en adelante el
              “Usuario”.
            </p>
            <p>
              2.2. El Usuario será responsable de los daños y perjuicios que
              fueran consecuencia de actos u omisiones propios del Usuario.
            </p>
            <p>
              2.3. Datos esenciales para el Programa Plus. Se considerarán datos
              esenciales para el correcto funcionamiento del programa los
              siguientes: Nombre, Apellido, documento de identidad, tipo de
              documento, género con el cual se identifica, fecha de nacimiento,
              email, dirección, departamento, localidad y celular.
            </p>
          </div>
          <span className="number-item-title">3. PROGRAMA PLUS</span>
          <div>
            <p>
              3.1. PLUS es un programa por el cual los Usuarios podrán adquirir
              – entre otros beneficios - Puntos Canjeables mediante sus
              compras-en efectivo, tarjetas de debito, tarjetas de crédito,
              ticket alimentación electrónico y tarjeta Uruguay Social- en
              cualquier unidad de negocio de la Empresa, y canjearlos por
              cualquier producto disponible en las unidades de negocio de la
              Empresa.
            </p>
            <p>
              3.2. El Usuario será responsable frente a la Empresa y cualquier
              tercero por el uso inadecuado del Programa desde su Usuario.
            </p>
            <p>
              3.3. La Empresa se reserva el derecho de dar de baja o inhabilitar
              al Usuario del Programa cuando: i) a solo criterio de la Empresa,
              considere que se hace o ha hecho uso indebido o inconveniente del
              mismo, o que por cualquier otro motivo que la Empresa considere el
              usuario no debe seguir participando del Programa; hipótesis en la
              cual el Usuario perderá la totalidad de los Puntos Canjeables
              obtenidos hasta el momento; ii) el Usuario se niegue a dar o
              solicite la eliminación de alguno de los siguientes datos
              personales proporcionados para el alta como Usuario e
              imprescindibles para el correcto desarrollo del programa: Nombre,
              Apellido, documento de identidad, género con el cual se
              identifique, fecha de nacimiento, email, dirección, departamento,
              localidad y celular. En esta hipótesis, el Usuario que cuente con
              Puntos Plus generados, contara´ con un plazo de 30 días para
              utilizarlos, una vez transcurrido dicho plazo se perderá la
              totalidad de los Puntos Canjeables generados.
            </p>
            <p>
              3.4. El puntaje generado en un Usuario solo podrá ser transferible
              a otros Usuarios exclusivamente a través de la APP de Plus, y
              también en aquellas situaciones que a sola voluntad de la Empresa
              puedan ser consideradas como una excepción.
            </p>
          </div>
          <span className="number-item-title">4. ÁMBITO DE APLICACIÓN</span>
          <div>
            <p>
              4.1. Los Usuarios podrán obtener Puntos Canjeable que se
              acreditarán a su Usuario PLUS en oportunidad de la adquisición de
              cualquier producto en cualquier unidad de negocio de la Empresa,
              siempre que dicha adquisición se abone mediante el pago con
              efectivo, tarjetas de débito, tarjetas de crédito, ticket
              alimentación electrónico y tarjeta Uruguay Social. Las
              adquisiciones que sean abonadas totalmente mediante otras formas
              de pago, diferentes a las antes mencionadas, no acumularán Puntos
              Canjeables. En caso de adquisiciones que sean abonadas
              parcialmente mediante otras formas de pago, acumularán puntos solo
              por el monto pago en efectivo, tarjetas de débito, tarjetas de
              crédito, ticket alimentación electrónico y tarjeta Uruguay Social.
            </p>
            <p>
              4.2. Los Puntos Canjeables son adjudicados en función del precio
              de la adquisición efectuada, a razón de 1 (un) Punto por cada $100
              (cien pesos uruguayos) en compras. Toda adquisición por monto
              inferior al indicado no generara´ Puntos Canjeables, no generando
              tampoco Puntos Canjeables la fracción resultante inferior a la
              suma indicada. Toda devolución de mercadería implicara´ la
              deducción de los Puntos Canjeables generados en dicha compra.
            </p>
            <p>
              4.3. En cada operación de compra que el Usuario realice en la
              Empresa se le informara´, conjuntamente con el ticket de compra,
              la cantidad de Puntos Canjeables generados, así como el saldo al
              momento.
            </p>
          </div>

          <span className="number-item-title">5. PRODUCTOS CANJEABLES.</span>
          <div>
            <p>
              5.1. Los Puntos Canjeables obtenidos por cada Usuario podrán ser
              canjeados por cualquier producto que se encuentre a la venta en
              cualquier unidad de negocio de la Empresa, o en catálogos
              especiales de productos o servicios para utilizarse en otras
              empresas, por acuerdos que se encuentren vigentes al momento del
              canje.
            </p>
            <p>
              5.2. Para poder realizar un canje, el Usuario deberá tener: a)
              como mínimo 51 Puntos Canjeables acumulados en el saldo disponible
              y; b) completos los datos del Usuario que el Programa “PLUS” a
              dicho momento requiera.
            </p>
            <p>
              5.3. Solicitado el canje por parte del Usuario PLUS, deberá
              presentar su documento que acredite válidamente su identidad, y
              efectuado el mismo, se debitara´ del saldo disponible del Usuario
              PLUS el equivalente al precio del producto adquirido.
            </p>
            <p>
              5.4. Solamente el titular de la Cuenta PLUS podrá solicitar canje,
              y siempre presentando su documento de identidad vigente y en
              buenas condiciones.
            </p>
          </div>

          <span className="number-item-title">
            6. COMUNICACIONES Y CONSULTAS
          </span>
          <div>
            <p>
              6.1. El Usuario PLUS puede resolver cualquier consulta que tenga,
              o cualquier comunicación que debiera efectuar con relación al
              Programa, a través del sitio web{' '}
              <a href="www.plus.uy " className="tata-link">
                www.plus.uy{' '}
              </a>{' '}
              o mediante el siguiente número telefónico 08008065.
            </p>
          </div>

          <span className="number-item-title">
            7. USUARIOS PLUS SIN ACTIVIDAD Y VENCIMIENTO DE PUNTOS GENERADOS.
          </span>
          <div>
            <p>
              7.1. Se considera Usuario PLUS sin actividad todo aquel que no
              registre actividad dentro de su Cuenta—ya fuere por adquisición de
              productos o canje de puntos—por un periodo de tres (3) meses
              corridos a contar del último movimiento.
            </p>
            <p>
              7.2. Verificado el transcurso del lapso indicado en el apartado
              anterior, caducarán los Puntos Canjeables obtenidos de pleno
              derecho, y el saldo del Usuario PLUS será de cero (0).
            </p>
          </div>

          <span className="number-item-title">
            8. MODIFICACION DE LAS CONDICIONES GENERALES DE USO.
          </span>
          <div>
            <p>
              8.1. La Empresa se reserva el derecho de modificar total o
              parcialmente las presentes bases y condiciones en cualquier
              momento. Las modificaciones a las Bases y Condiciones se
              notificaran al ingresar a la pagina web{' '}
              <a href="www.plus.uy " className="tata-link">
                www.plus.uy{' '}
              </a>
              mediante una ventana en modo Pop-up y/o a través de cualquier otra
              forma que la Empresa pueda determinar, y los Usuarios tendrán un
              plazo de 30 días para aceptarlas o darse de baja del programa, en
              caso de no pronunciarse, al pasar los 30 días se tomarán por
              aceptadas.
            </p>
            <p>
              8.2. Las modificaciones que la Empresa realice tendrán efecto a
              partir de los 30 días de notificado el cambio de las mismas. Se
              aplicarán a todos los Usuarios PLUS activos a la fecha de la
              modificación y las personas que posterior a dicha modificación
              sean Usuarios PLUS.
            </p>
            <p>
              8.3. La modificación de las presentes Bases y Condiciones será
              publicada en el sitio{' '}
              <a href="www.plus.uy " className="tata-link">
                www.plus.uy{' '}
              </a>{' '}
              dentro del Programa PLUS.
            </p>
            <p>
              8.4. La Empresa no será responsable, en ningún caso, por las
              modificaciones totales o parciales de las Bases y Condiciones del
              presente Programa.
            </p>
          </div>

          <span className="number-item-title">9. FINALIZACIÓN</span>
          <div>
            <p>
              9.1. La Empresa se reserva el derecho de finalizar el Programa en
              cualquier momento mediante previo aviso de un mes a los Usuarios
              PLUS en la web{' '}
              <a href="www.plus.uy " className="tata-link">
                www.plus.uy
              </a>
              .
            </p>
          </div>

          <span className="number-item-title">10. RESPONSABILIDADES</span>
          <div>
            <p>
              10.1. La Empresa no se hace responsable de la utilización
              deficiente o fraudulenta por parte de los Usuarios PLUS ni de
              terceros del presente Programa.
            </p>
            <p>
              10.2. El Usuario será responsable de los daños y perjuicios que
              fueran consecuencia de actos u omisiones propios del Usuario.
            </p>
          </div>

          <span className="number-item-title">11. LEGISLACIÓN</span>
          <div>
            <p>
              11.1. Las Bases y Condiciones del Programa se regirán,
              interpretarán, cumplirán y ejecutarán conforme a las leyes y
              normas de la República Oriental del Uruguay, en todo lo que
              expresamente no se haya modificado en la presente, conforme a la
              autonomía de la voluntad de los contratantes.
            </p>
          </div>
          <span className="number-item-title">
            12. CONSIDERACIONES FINALES.
          </span>
          <div>
            <p>
              12.1. 1. La eventual nulidad de alguna de las cláusulas del
              presente no importara´ la nulidad de las restantes.
            </p>
            <p>
              12.2. El hecho que la Empresa omita exigir el cumplimiento de un
              término o condición en particular del Programa o más de uno de
              ellos, no constituye una renuncia a dicho término o condición para
              la Empresa.
            </p>
            <p>
              12.3. La participación en el Programa PLUS implica la aceptación
              de todas las condiciones establecidas en el presente, las que se
              consideran conocidas por todos los Usuarios desde su Adhesión al
              Programa.
            </p>
            <p>
              12.4. La Empresa se reserva el derecho de modificar las presentes
              Bases y Condiciones sin previo aviso.
            </p>
            <p>
              12.5. La Empresa se reserva el derecho de interpretar estas bases
              en caso de duda o discrepancia como así también de modificar las
              mismas ante cualquier imprevisto o eventualidad, sin incurrir por
              ello en responsabilidad de clase alguna.
            </p>
            <p>
              12.6. En caso de problemas informáticos, donde por ejemplo se
              otorguen puntos de manera incorrecta, la Empresa será la encargada
              de revertir ese error, siendo su decisión no discutible.
            </p>
            <p>
              12.7. En caso de fallecimiento del Usuario, no existiendo al
              momento del suceso Usuario Adicional declarado, los puntos
              acumulados y/o los beneficios canjeados quedarán a disposición de
              la Empresa. En caso que, al momento del fallecimiento del Usuario,
              la cuenta tenga un Usuario Adicional declarado, este podrá
              solicitar el cambio de Titularidad de los puntos plus previa
              presentación de la documentación que acredite el fallecimiento del
              Usuario y firma de la carta correspondiente de cambio de
              titularidad. Para realizar esta gestión, el Usuario Adicional
              contará con plazo de 180 días..
            </p>
          </div>
          <span className="number-item-title">
            13. ACTUALIZACIÓN DE DATOS DEL USUARIO
          </span>
          <div>
            <p>
              13.1. El Usuario deberá comunicar en forma inmediata y
              fehaciente--mediante las vías aceptadas en las Bases y
              Condiciones--a la Empresa, de cualquier cambio que se produzca en
              sus datos personales. Si así no lo hiciese será causal suficiente
              para poder ser dado de baja del Programa PLUS.
            </p>
            <p>
              13.2. Cualquier comunicación cursada por la Empresa a un Usuario
              se considerara´ notificada si fuere remitida al domicilio y/o
              telefónicamente y/o a través de correo electrónico a su dirección
              de correo obrante en los registros de la Empresa.
            </p>
            <p>
              13.3. La Empresa no será responsable, en ningún caso, de reclamos
              que tuviere como fundamento la falta de información del Usuario o
              el manejo por parte del Usuario de fuentes de información
              desactualizadas.
            </p>
          </div>

          <span className="number-item-title">14. RENUNCIA</span>
          <div>
            <p>
              14.1. El Usuario podrá poner fin a su participación en el Programa
              en cualquier momento completando en cualquier unidad de negocio de
              la Empresa la solicitud de baja del servicio, en adelante
              “Solicitud de Baja” o comunicándose al correo electrónico
              consultas.basededatos@tata.com.uy. Una vez el Usuario sea dado de
              baja no podrá disponer del saldo de Puntos Canjeables que tuviere
              acumulado, quedando este en 0 (cero).
            </p>
          </div>
          <span className="number-item-title">
            15. PROTECCIÓN DE DATOS Y CESIÓN DE DERECHO DE USO DE LA IMAGEN
          </span>

          <div>
            <p>
              15.1. Los datos personales proporcionados, serán tratados por TA
              TA S.A. según lo establecido en la Ley Nº 18.331 del 11 de agosto
              de 2008 y su decreto reglamentario Nº 414/2009 del 31 de agosto de
              2009, modificativas y concordantes, y de acuerdo a lo establecido
              en “Políticas de Privacidad de Plus”.
            </p>
          </div>
        </div>
      </section>
      <div className="newsletter-container-inst-page">
        <Newsletter
          title="Recibí promociones y novedades"
          subtitle="Ingresá tu correo electrónico"
        />{' '}
      </div>
      <StockAvailable icon />
    </>
  )
}

export const query = graphql`
  query LandingPageTerminosQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    allCmsInstitutionalPage(
      filter: {
        seo: {
          siteMetadataWithSlug: { slug: { eq: "/lp/terminos-y-condiciones" } }
        }
      }
    ) {
      edges {
        node {
          seo {
            siteMetadataWithSlug {
              slug
            }
          }
          name
          sections {
            data
          }
        }
      }
    }
  }
`

export default Page

export { Head } from 'src/components/common/Head'
